import styled, {keyframes} from 'styled-components';
import boxBg from './assets/box_bg.png';
import singularityLogo from './assets/singularity_logo.svg';
import coinsBg from './assets/coins.png';

export const ParentContainer = styled.div`
  height: 100vh;
  width: 100vw;
  background: url(${boxBg}) no-repeat center center fixed;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
export const MainContentContainer = styled.div`
  margin-left: 136px;
  margin-right: 136px;
  width: calc(100vw - 272px);
  overflow-x: hidden;
  box-sizing: border-box;

  @media (max-width: 599px) {
    /* CSS rules for small devices */
    margin-left: 16px;
    margin-right: 16px;
    width: calc(100vw - 32px);
  }
`;
export const ContentContainer = styled.div`
  z-index: 7;
  display: flex;
  flex-direction: column;

  /* Small devices (phones, less than 600px) */
  @media (max-width: 599px) {
    /* CSS rules for small devices */
    //margin-left: 16px;
    margin-top: 0px;
  }

  /* Medium devices (tablets, 600px to 899px) */
  @media (min-width: 600px) and (max-width: 899px) {
    /* CSS rules for medium devices */
  }

  /* Large devices (desktops, 900px to 1199px) */
  @media (min-width: 900px) and (max-width: 1199px) {
    /* CSS rules for large devices */
  }

  /* Extra large devices (large desktops, more than 1200px) */
  @media (min-width: 1200px) {
    /* CSS rules for extra large devices */
  }
  
`
export const SingularityLogo = styled.div`
  z-index: 7;
  background: url(${singularityLogo}) no-repeat center center;
  background-size: contain; /* Ensure the image scales correctly */
  width: 190px;
  height: 34px;
  position: absolute;
  margin-left: 138px;
  margin-top: 26px;

  /* Small devices (phones, less than 600px) */
  @media (max-width: 599px) {
    /* CSS rules for small devices */
    width: 150px;
    height: 30px;
    margin-left: 16px;
    margin-top: 16px;
  }

  /* Medium devices (tablets, 600px to 899px) */
  @media (min-width: 600px) and (max-width: 899px) {
    /* CSS rules for medium devices */
  }

  /* Large devices (desktops, 900px to 1199px) */
  @media (min-width: 900px) and (max-width: 1199px) {
    /* CSS rules for large devices */
  }

  /* Extra large devices (large desktops, more than 1200px) */
  @media (min-width: 1200px) {
    /* CSS rules for extra large devices */
  }
  
`;
export const CoinsBgContainer = styled.div`
  z-index: 5;
  position: absolute;
  right: 0;
  width: 60vw;
  height: 100vh;
  display: flex;
  justify-content: center; /* Centers horizontally */
  align-items: center; /* Centers vertically */
  overflow: hidden;

  @media (max-width: 1200px) {
    display: none;
  }
`;

export const CoinsBgImage = styled.img`
  width: 100%;
  height: auto;
  object-fit: cover;
`;
export const MainHeadingContainer = styled.div`
  font-family: Inter,serif;
  font-size: 48px;
  font-weight: 400;
  line-height: 58px;

  @media (max-width: 599px) {
    /* CSS rules for small devices */
    margin-left: 0px;
    font-size: 32px;
    line-height: 42px;
  }
`
export const AccessYour = styled.div`
    color: white;
`
export const SingularityWallet = styled.div`
    color: #2B66F6;
`
export const StepsContainer = styled.div`
  z-index: 7;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 31px;
  //margin-left: 136px;
  margin-top: 34px;

  @media (max-width: 599px) {
    /* CSS rules for small devices */
    //margin-left: 16px;
    margin-top: 24px;
    gap: 24px;
  }
`;

export const Step = styled.div`
  display: flex;
  align-items: flex-start;
  position: relative;

  &:not(:last-child)::after {
    content: '';
    position: absolute;
    left: 15.5px;
    top: 30px;
    bottom: -34px;
    width: 1px;
    background: repeating-linear-gradient(
      transparent,
      transparent 4px,
      #FFFFFF69 4px,
      #FFFFFF69 8px
    );
  }
`;

export const StepNumber = styled.div`
  font-size: 17px;
  line-height: 22px;
  font-family: Poppins,serif;
  color: #fff;
  background-color: #353535;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;

  @media (max-width: 599px) {
    font-size: 17px;  // Ensures font size remains consistent
    width: 32px;      // Maintains width at 32px
    height: 32px;     // Maintains height at 32px
  }
`;

export const StepText = styled.div`
  width: calc(100% - 44px);
  margin-left: 12px;
`;

export const StepTitle = styled.div`
  font-family: Poppins,serif;
  font-size: 18px;
  line-height: 22px;
  color: #fff;
  font-weight: 600;
  margin-bottom: 4.5px;

  @media (max-width: 599px) {
    font-size: 16px;
    line-height: 22px;
  }
`;
export const StepSubtitle = styled.div`
  font-family: Be Vietnam Pro,serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 21px;
  text-align: left;
  color: #8F8F8F;

  @media (max-width: 599px) {
    font-size: 12px;
    line-height: 16px;
  }
`;
export const SubmitButton = styled.button`
  z-index: 7;
  width: 212px;
  height: 51px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 36px;
  background: ${props => (props.loading ? '#2B66F6' : '#2B66F6')};
  //margin-left: 141px;
  font-family: Inter, serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 21.78px;
  text-align: center;
  color: #ffffff;
  border-radius: 8px;
  border: none;
  cursor: ${props => (props.loading ? 'not-allowed' : 'pointer')};
  pointer-events: ${props => (props.loading ? 'none' : 'auto')};
  position: relative;

  @media (max-width: 599px) {
    font-size: 16px;
    margin-top: 24px;
    width: 160px;
    height: 44px;
    margin-left: 0px;
  }
`;

export const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

export const Loader = styled.div`
  border: 4px solid #f3f3f3;
  border-top: 4px solid #2B66F6;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  animation: ${spin} 1s linear infinite;

  @media (max-width: 599px) {
    width: 16px;
    height: 16px;
  }
`;
