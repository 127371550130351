import './App.css';
import {
    AccessYour, CoinsBg, CoinsBgContainer, CoinsBgImage,
    ContentContainer, Loader, MainContentContainer,
    MainHeadingContainer,
    ParentContainer, SingularityLogo,
    SingularityWallet, Step, StepNumber,
    StepsContainer, StepSubtitle, StepText, StepTitle, SubmitButton
} from "./styles";
import {useEffect, useState} from "react";
import {initializeSingularity} from "singularity-init";
import coinsBg from './assets/coins.png';


function App() {

    const [isLoading, setIsLoading] = useState(false)


    useEffect(() => {
        setIsLoading(true)
        initializeSingularity(window, document,'latest','production','singularity-wallet-login',async () => {
            setIsLoading(false)
        })
    }, [])

    const onGetStartedClicked = () => {
        if(isLoading)return
        window.SingularityEvent.open()
    }


  return (
      <>
          <SingularityLogo />
    <ParentContainer>


        <MainContentContainer className={"MainContentContainer"}>

            <ContentContainer>
                <MainHeadingContainer>
                    <AccessYour>Access your</AccessYour>
                    <SingularityWallet>Singularity Wallet</SingularityWallet>
                </MainHeadingContainer>
            </ContentContainer>

            <StepsContainer>
                <Step>
                    <StepNumber>1</StepNumber>
                    <StepText>
                        <StepTitle>Log in to your Singularity account via Google Sign-in</StepTitle>
                        <StepSubtitle>Please make sure you use the same Google Account you used previously</StepSubtitle>
                    </StepText>
                </Step>
                <Step>
                    <StepNumber>2</StepNumber>
                    <StepText>
                        <StepTitle>Select the chain from the drop down</StepTitle>
                        <StepSubtitle>Find the chain that has the tokens you are looking to transfer</StepSubtitle>
                    </StepText>
                </Step>
                <Step>
                    <StepNumber>3</StepNumber>
                    <StepText>
                        <StepTitle>Select token and hit “send”</StepTitle>
                        <StepSubtitle> Make sure you enter the correct destination address for your tokens</StepSubtitle>
                    </StepText>
                </Step>
            </StepsContainer>

            <SubmitButton onClick={onGetStartedClicked} loading={isLoading}>
                {isLoading ? <Loader /> : 'Get Started'}
            </SubmitButton>

        </MainContentContainer>

        {/*<CoinsBg />*/}
        <CoinsBgContainer>
            <CoinsBgImage src={coinsBg} alt="Background" />
        </CoinsBgContainer>

    </ParentContainer>
      </>
  );
}

export default App;
